<template>
  <v-app>
    <supportdashboard-core-app-bar />

    <!-- <supportdashboard-core-drawer /> -->

    <supportdashboard-core-view />

    <!-- <supportdashboard-core-settings /> -->
  </v-app>
</template>

<script>
  export default {
    name: 'supportdashboardIndex',

    components: {
      supportdashboardCoreAppBar: () => import('./components/core/AppBar'),
      supportdashboardCoreDrawer: () => import('./components/core/Drawer'),
      supportdashboardCoreSettings: () => import('./components/core/Settings'),
      supportdashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
